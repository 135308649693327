.alarmContainer{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

}
.cardContainer{
    width: 60%;
    height: 70%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 20px;
}



.cardBody{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 50px;
    margin-left: 30px;
}
.cardIcerik{
    display: flex;
    flex-direction: row;
    margin-top: -30px;
}
.cardElse{

    font-size: 70px;
    font-weight: bolder;
    
}
.title{
    width: 360px ;
    font-weight: bolder;    
    font-size: 38px;
}
.value{
    font-size: 50px;
    word-wrap: break-word;
    width: 75%;
    font-weight: bolder;
    margin-top: 30px;
}