/* .homePageCard {
    display: flex;
    width: 400px;
    height: 400px;
    background-color: white;
    opacity: 0.7;
    border-radius: 20px;
    border: 2px solid gray;
    margin-top: 8.2%;
    margin-right: 25%;
    box-shadow: 10px;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 3%;
} */
.terms-box{
    display: flex;
    /* width: 400px; */
    height: 400px;
    background-color: white;
    opacity: 0.7;
    border-radius: 20px;
    border: 2px solid gray;
    margin-top: 10%;
    margin-right: 38.5%;
    box-shadow: 10px;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 3%;
    max-width: 420px;
    padding: 30px 30px;
}
.terms-text{
    padding: 0px 0px;
    height: 280px;
    width: 340px;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 11px;
    font-weight: 400;
    border: 0.5px solid rgb(228, 225, 225);


}
@media (min-width: 1025px) and (max-width: 1280px) {
    .terms-box{
        display: flex;
        /* width: 400px; */
        height: 350px;
        background-color: white;
        opacity: 0.7;
        border-radius: 20px;
        border: 2px solid gray;
        margin-top: 7%;
        margin-right: 38.5%;
        box-shadow: 10px;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3%;
        max-width: 420px;
        padding: 30px 30px;
    }
    .terms-text{
        padding: 0px 0px;
        height: 220px;
        width: 340px;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 11px;
        font-weight: 400;
        border: 0.5px solid rgb(228, 225, 225);
    
    }
}

/* .terms-text::-webkit-scrollbar{
    width: 2px;
    background-color: black;
} */

.terms-box:hover {
    box-shadow: rgba(255, 255, 255, 0.15) 0px 15px 25px, rgba(255, 255, 255, 0.05) 0px 5px 10px;
    opacity: 1;
}


.signInOn {
    width: max-content !important;
    background-color: black;
    height: 30px;
    margin-top: 3px !important;
    color: white ;
    border: 1px solid rgb(185, 185, 185) !important;
}
.signInOn:hover{
    color: black !important;
    background-color: #ffb71b !important;
}
