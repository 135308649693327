@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: grey;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.homePage {
  /* The image used */
  background-image: url("./assets/image/bshekran.png");
  height: 100vh !important;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100vw !important;
}

.active {
  background-color: #fff !important;
  color: black !important;
  border-radius: 3px;
  padding: 8px;
}

.App {
  display: flex;
  flex-direction: column;
  /* gap: 10px;
  padding: 20px; */
  height: 100vh;
  background-color: rgba(235, 241, 250);
  font-family: "Inter", sans-serif;
  overflow: auto;
}
.table-row-light {
  background-color: #ffffff !important;
}

.table-row-dark {
  background-color: #f0f0f0 !important;
}

.table-first-item {
  background-color: #f1b634 !important;
}
.table-first-item-complete {
  background-color: rgb(165, 157, 157) !important;
}

.table-first-item:hover {
  background-color: #f1b634 !important;
}

.ant-table-cell-row-hover {
  background-color: unset !important;
}

/* .ant-table-tbody > tr > td {
  padding: 10px !important;
}

.table-padding {
  padding: 0 !important;
} */
.ant-table-body {
  font-weight: 600;
  font-size: 17px;
}
.ant-table-thead {
  font-weight: 600;
  font-size: 17px;
  text-decoration: underline;
}

.ant-pagination {
  background-color: #ffffff !important;
  margin: 0px !important;
  padding: 20px;
}
.table-main
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-pagination
  > .ant-pagination-options
  > .ant-select {
  display: none !important;
}

.ant-modal-wrap {
  top: 15% !important;
}
.grid-container {
  grid-gap: 10px;
}

.ant-table-row {
  height: 80px;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-table-placeholder {
  z-index: 0 !important;
}
.ant-btn-primary{
  background-color: #f1b634;
}
.table-first-6-rows {
  background-color: rgb(190, 190, 190);
}

.ant-tabs .ant-tabs-ink-bar{
  background: #f1b634;
  
}
.ant-tabs-nav-list > .ant-tabs-ink-bar-animated{
  height: 3px !important;
}
.ant-pagination {
  padding: 10px;
}

.ant-table-cell{
  font-size: 15px;
}
.ant-table-tbody > tr > td {
  transition: background 999s !important;
  transition-property: background !important;
  transition-duration: 9999s !important;
  transition-timing-function: ease !important;
  transition-delay: 0s !important;
}
