.flip-clock {
  --fcc-flip-duration: 0.7s; /* transition duration when flip card */
  --fcc-digit-block-width: 105px; /* width of digit card */
  --fcc-digit-block-height: 130px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 100px; /* font size of digit */
  --fcc-digit-color: #fff; /* color of digit */
  --fcc-label-font-size: 40px; /* font size of label */
  --fcc-label-color: black; /* color of label */
  --fcc-background: #f1b634; /* background of digit card */
  --fcc-divider-color: #fff; /* color of divider */
  --fcc-divider-height: 0.1px; /* height of divider */
  --fcc-separator-size: 16px; /* size of colon */
  --fcc-separator-color: #f1b634; /* color of colon */
}
