.submitButton {
  display: flex;
  float: right;
  margin-top: 20px;
  height: 70px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}
.input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}
.input-group input {
  width: 150px;
  height: 100px;
  font-size: 100px;
  text-align: center;
}
.changeOrderSelect {
  height: 50px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.changeReason {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}
.changeReason label {
  font-size: 15px;
  white-space: nowrap;
  font-weight: bold;
}
.changeOrderIcon {
  display: flex;
  align-items: center;
  font-size: 50px;
  margin-top: 40px;
}

.ant-modal-title {
  font-size: 30px !important;
  font-weight: bold !important;
  text-decoration: underline;
}
.input-group label {
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center;
}
.changeOrderSelect option {
  font-size: 20px !important;
  font-weight: bold !important;
}

.order-error {
  color: red;
  font-weight: bold;
}
.changeOrderModal {
  width: 700px !important;
}
